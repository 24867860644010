<div class="panel-container">
  <div class="panel-details">
    <div class="card-view">
      <div class="education">
        <h2 class="">Experience</h2>
        <!-- vertical timeline -->
        <ul class="clr-timeline clr-timeline-vertical">
          <li class="clr-timeline-step">
            <div class="clr-timeline-step-header">Sep. 2013</div>
            <clr-icon
              shape="success-standard"
              aria-label="Completed"
            ></clr-icon>
            <div class="clr-timeline-step-body">
              <span class="clr-timeline-step-title">UofT</span>
              <span class="clr-timeline-step-description"
                >&#9728; University - Electrical and Computer Engineering</span
              >
            </div>
          </li>
          <li class="clr-timeline-step">
            <div class="clr-timeline-step-header">May 2016</div>
            <clr-icon
              shape="success-standard"
              aria-label="Completed"
            ></clr-icon>
            <div class="clr-timeline-step-body">
              <span class="clr-timeline-step-title">TheRedPin,Inc. Intern</span>
              <span class="clr-timeline-step-description">
                &#8627; Developed frontend and backend code for several web
                pages of the company’s main website.
              </span>
              <span class="clr-timeline-step-description">
                &#8627; Led the development of an in-house mobile app in IOS and
                Android, published online.
              </span>
              <span class="clr-timeline-step-description">
                &#8627; Fixed invalid and duplicated regional geographic
                boundaries in database using POSTGIS. Migrated data from Mysql
                to Postgres.
                <!-- <img src="assets/placeholder_350x150.png" alt="Example of an Image in a Card" /> -->
              </span>
              <span class="border-span"></span>
              <!-- <img
              src="https://clarity.design/assets/images/documentation/cards/placeholder_350x150.png"
              alt="Example of an Image in a Card"
            /> -->
            </div>
          </li>

          <li class="clr-timeline-step">
            <span class="clr-timeline-step-header">Jun. 2018</span>
            <clr-icon
              shape="success-standard"
              aria-label="Completed"
            ></clr-icon>
            <div class="clr-timeline-step-body">
              <span class="clr-timeline-step-title">UofT - Completed</span>
              <span class="clr-timeline-step-description">
                &#9728; University - Electrical and Computer Engineering.
              </span>
            </div>
          </li>

          <li class="clr-timeline-step">
            <div class="clr-timeline-step-header" style="word-break: keep-all">
              May 2018
            </div>
            <clr-icon
              shape="success-standard"
              aria-label="Completed"
            ></clr-icon>

            <div class="clr-timeline-step-body">
              <span class="clr-timeline-step-title"
                >Trapeze Software Inc.
              </span>
              <span class="clr-timeline-step-description"
                >&#8627; Developed an IOS application using Swift from scratch
                to finish, with over 25 screens and various functionalities such
                as viewing and printing documents, adding and modifying
                requests, <br />
                viewing and editing work schedules, managing account profile,
                messaging, notification and so on.
              </span>
              <span class="clr-timeline-step-description"
                >&#8627; Maintained and enhanced an existing native Android
                Application(Kotlin, JAVA).
              </span>
              <span class="clr-timeline-step-description"
                >&#8627; Implemented and improved existing mobile APIs in C++,
                strengthened APIs and user account security by adding JWT,
                password hashing and salting, and account lockout threshold
                <br />
                for unsuccessful login attempts.
              </span>
              <span class="clr-timeline-step-description"
                >&#8627;Optimized performance and user experience on a legacy
                website (JS, CSS, XSLT), optimized performance on a server
                application(C++, SQL Server), constructed complex queries <br />
                to export data to PDF reports using Crystal report.
              </span>
              <span class="clr-timeline-step-description"
                >&#8627; Transformed legacy web application with new design
                using Angular and .NET, with extensive test coverage(over 600
                unit test cases for Angular).
              </span>
              <span class="clr-timeline-step-description"
                >&#8627; Designed a complex grid system that renders table-like
                data with different data types from database.
              </span>
              <span class="clr-timeline-step-description"
                >&#8627; Rebuilt a legacy Windows Desktop application using
                Angular and .NET in Microservices architecture.
              </span>
            </div>
          </li>
          <li class="clr-timeline-step">
            <div class="clr-timeline-step-header" style="word-break: keep-all">
              Mar 2022 - present
            </div>
            <clr-spinner clrMedium aria-label="In progress"
              >Fetching data</clr-spinner
            >

            <div class="clr-timeline-step-body">
              <span class="clr-timeline-step-title"> Box </span>
              <span class="clr-timeline-step-description"
                >&#8627; Maintained and enhanced an internal NodeJS server
                framework that is utilized by 20+ services with over 10 of them
                running in production. Handled primary on-call for framework
                related questions and issues.<br />
                Continuously developed and updated framework tools to optimize
                performance and functionality.
              </span>
              <span class="clr-timeline-step-description"
                >&#8627; Maintained and enhanced an in-house proxy server to
                support both frontend and backend development.
              </span>
              <span class="clr-timeline-step-description"
                >&#8627; Contributed to the next gen GraphQL server to replace
                legacy NodeJS services in production.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Implemented performance testing with
                Locust, created Jenkins pipeline to run performance tests and
                publish test reports weekly.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Improved development efficiency by
                enabling E2E tests to run locally.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Implemented various graphQL plugins like
                loader performance profiler, rate limiter, query complexity
                calculator, etc.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Contributed to converting the codease
                from javascript to typescript.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Streamlined code review process by
                implementing automated changelog generation and schema breaking
                change detection.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Regularly fix critical dependencies
                vulnerabilities to improve server security.
              </span>
              <span class="clr-timeline-step-description">
                &#8627; Worked closely with the infrastructure team to migrate 3
                NodeJS services to Google Cloud Platform.
              </span>
              <span class="clr-timeline-step-description">
                &#8627; Collaborated within the Box Notes team, implemented the
                background migration and ondemand migration logic to migrate
                real time Box Notes to a newer format.
              </span>
              <span class="clr-timeline-step-description">
                &#8627; Contributed to the Developer Tooling
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Contributed to a Box CLI tool for
                supporting Box internal application development.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Significantly Improved developer
                experience in the Box monolithic tooling repo by migrating it to
                use NX as the Build system.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Significantly improved the developer
                experience by reducing the build time for NodeJS services by
                10x.
              </span>
              <span class="clr-timeline-step-description"
                >&nbsp; &nbsp; &#8627; Enhanced Box internal E2E testing library
                by adding more APIs.
              </span>
            </div>
          </li>

          <li class="clr-timeline-step">
            <div class="clr-timeline-step-header"></div>
            <clr-icon shape="circle" aria-label="Not started"></clr-icon>
            <div class="clr-timeline-step-body">
              <span
                class="clr-timeline-step-title clickable"
                (click)="scrollTo()"
                >Contact
              </span>
              <span class="clr-timeline-step-description"></span>
            </div>
          </li>
        </ul>
      </div>
      <div class="bottom-bar"></div>
    </div>
  </div>
</div>
